import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './AgendaTimeStatusOnDemand.scss';

export default function AgendaTimeStatusOnDemand(props) {
  const { statusText = '', isBoldStatus } = props;
  const componentClass = classnames('c-agenda-time-status-on-demand', {
    'c-agenda-time-status-on-demand--bold': isBoldStatus,
  });
  return (
    <span className={componentClass}>
      <span>On Demand </span>
      {statusText && (
        <span className="c-agenda-time-status-on-demand__text">
          {statusText}
        </span>
      )}
    </span>
  );
}

AgendaTimeStatusOnDemand.propTypes = {
  statusText: PropTypes.string,
  isBoldStatus: PropTypes.bool,
};
