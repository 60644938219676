import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, SubmissionError } from 'redux-form';
import classnames from 'classnames';
import { isFunction } from '../../../helpers';
import BaseSectionTitle from '../../siteBaseSections/baseSectionComponents/BaseSectionTitle/BaseSectionTitle';
import BaseSectionText from '../../siteBaseSections/baseSectionComponents/BaseSectionText/BaseSectionText';
import CallToAction from '../CallToAction/CallToAction';
import './ReduxFormWrapper.scss';

export class ReduxFormWrapper extends Component {
  state = {
    loading: false,
  };

  submitHandler = (values) => {
    this.setState({
      loading: true,
    });

    let statusCode = 200;
    return this.props
      .submitAction(values)
      .then((response) => {
        statusCode = response.status;
        return response.json();
      })
      .then((response) => {
        this.setState({
          loading: false,
        });

        let errorMessageConfig;
        if (isFunction(this.props.onSubmit)) {
          errorMessageConfig = this.props.onSubmit(response, statusCode);
        }

        if (errorMessageConfig && Object.keys(errorMessageConfig).length) {
          throw new SubmissionError(errorMessageConfig);
        }

        const { error, errors = {} } = response;
        if (error || Object.keys(errors).length > 0) {
          throw new SubmissionError({
            ...errors,
            _error: error,
          });
        }
      });
  };

  render() {
    const {
      form,
      error,
      heading,
      headingSentenceCase,
      subHeading,
      callToAction,
      alternativeProposition,
      handleSubmit,
      isClearSubmitCtaMargin,
      children,
    } = this.props;

    const submitCTAClassNames = classnames('form-submit', {
      'form-submit--no-margin': isClearSubmitCtaMargin,
    });

    const { loading } = this.state;

    return (
      <form
        name={form}
        onSubmit={handleSubmit(this.submitHandler)}
        noValidate
        autoComplete="off"
        className="c-redux-form-wrapper"
      >
        {heading && (
          <BaseSectionTitle
            small
            text={heading}
            sentenceCase={headingSentenceCase}
          />
        )}
        {subHeading && (
          <BaseSectionText style={{ marginBottom: '20px' }} text={subHeading} />
        )}
        {children}
        {error && callToAction && <div className="form-error">{error}</div>}
        {callToAction && (
          <>
            <CallToAction
              type="submit"
              size="submit"
              mode="black"
              label={callToAction}
              loading={loading}
              className={submitCTAClassNames}
            />
            {alternativeProposition && (
              <div className="form-alt-proposition">
                {alternativeProposition}
              </div>
            )}
          </>
        )}
      </form>
    );
  }
}

ReduxFormWrapper.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: PropTypes.string.isRequired,
  heading: PropTypes.string,
  headingSentenceCase: PropTypes.bool,
  subHeading: PropTypes.string,
  isClearSubmitCtaMargin: PropTypes.bool,
  callToAction: PropTypes.string,
  alternativeProposition: PropTypes.node,
  onSubmit: PropTypes.func,
  submitAction: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default reduxForm()(ReduxFormWrapper);
