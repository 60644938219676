import { HTMLAttributes } from 'react';
import cn from 'classnames';
import s from './AgendaCustomLabel.module.scss';

interface LabelConfig {
  name: string;
  backgroundColor: number;
  textColor: number;
  checked: boolean;
}

interface AgendaCustomLabelProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  labelConfig: LabelConfig;
}

export default function AgendaCustomLabel(props: AgendaCustomLabelProps) {
  const {
    className,
    labelConfig: { backgroundColor, textColor, name },
  } = props;
  const labelClassName = cn(className, s.label, 'stream-background-color');

  return (
    <span className={labelClassName} data-stream-color-index={backgroundColor}>
      <span className="stream-color" data-stream-color-index={textColor}>
        {name}
      </span>
    </span>
  );
}
