import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import Auth from '../../../services/AuthService';

import MaximiseScreenWrapper from '../MaximiseScreenWrapper/MaximiseScreenWrapper';
import CallToAction from '../CallToAction/CallToAction';
import VirtualEventPropositionHeading from './VirtualEventPropositionHeading/VirtualEventPropositionHeading';
import LoginForm from '../../delegateJourney/LoginForm/LoginForm';

import './VirtualEventProposition.scss';

export class VirtualEventProposition extends Component {
  state = {
    accessChecked: false,
    accessGranted: false,
    iframeDisplayed: false,
    isShowLoginForm: false,
    loggedIn: Auth.getShortAccountDetails().loggedIn,
  };

  componentDidMount() {
    this.updateComponentState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.session.id !== this.props.session.id) {
      this.updateComponentState();
    }
  }

  updateComponentState() {
    const {
      session: {
        virtualConfig: { enabled: virtualEventEnabled, productCodes = [] } = {},
      } = {},
    } = this.props;

    if (virtualEventEnabled) {
      this.setState(() => ({
        accessChecked: false,
        accessGranted: false,
      }));

      // if productCodes are empty we provide full access
      if (!productCodes.length) {
        this.setState({
          accessChecked: true,
          accessGranted: true,
        });
      } else if (productCodes.length && this.state.loggedIn) {
        this.checkVirtualEventAccess();
      }
    }
  }

  checkVirtualEventAccess = async () => {
    const { session: { virtualConfig: { productCodes = [] } = {} } = {} } =
      this.props;
    const response = await Auth.checkEventAttendance(productCodes).catch(
      (error) => {
        console.error(error);
      },
    );

    this.setState({
      accessChecked: true,
      accessGranted: response.status === 200,
    });
  };

  toggleVirtualEventIframeDisplay = () => {
    const { handleSessionIframeOpening } = this.props;
    this.setState(
      (state) => ({
        iframeDisplayed: !state.iframeDisplayed,
      }),
      () => {
        handleSessionIframeOpening(this.state.iframeDisplayed);
      },
    );
  };

  getBaseUrl() {
    const {
      session: {
        id: sessionId,
        virtualConfig: { baseUrl = '', appendSessionId, appendUserEmail } = {},
      } = {},
    } = this.props;

    let finalBaseUrl = baseUrl.replace(/\/+$/, '');

    if (appendSessionId) {
      finalBaseUrl += '/' + sessionId;
    }

    if (appendUserEmail) {
      finalBaseUrl += '/' + Auth.getShortAccountDetails().email;
    }

    return finalBaseUrl ? finalBaseUrl + '/' : null;
  }

  handleLogInFormVisibility = () => {
    return () => {
      this.setState((state) => ({
        isShowLoginForm: !state.isShowLoginForm,
      }));
    };
  };

  getFormConfig() {
    const { pageConfig: { tenantName } = {}, t } = this.props;

    return {
      textContent: {
        customTitle: t('component.login.virtual-session.heading', {
          0: tenantName,
        }),
        callToActionLabel: t('component.login.virtual-session.cta'),
        fields: {
          username: t('component.login.virtual-session.fields.email'),
          password: t('component.login.virtual-session.fields.password'),
        },
        anchors: (
          <>
            <CallToAction
              type="link"
              to="/virtual/forgotten-password/"
              sitePath
              label={t('component.login.virtual-session.forgotten-password')}
            />
          </>
        ),
      },
    };
  }

  render() {
    const {
      session: {
        title,
        index: sessionIndex,
        virtualConfig: {
          enabled: virtualEventEnabled,
          buttonText,
          openingLinkType,
          iframeHeight,
          heading,
          description,
          productCodes = [],
        } = {},
      } = {},
      siteHeader: { data: { booking: isBooking } = {} } = {},
      i18n: { language } = {},
      t,
    } = this.props;

    const {
      loggedIn,
      accessChecked,
      accessGranted,
      iframeDisplayed,
      isShowLoginForm,
    } = this.state;
    const isShowVEP = productCodes.length ? loggedIn : true;
    const isBtnPaddings = language !== 'fr' && language !== 'de';
    const btnLoginClass = classnames('c-virtual-event-proposition__btn-login', {
      'c-virtual-event-proposition__btn-login--no-booking': !isBooking,
      'c-virtual-event-proposition__btn-login--with-paddings': isBtnPaddings,
    });
    const btnBookNowClass = classnames({
      'c-virtual-event-proposition__btn-book-now--with-paddings': isBtnPaddings,
    });

    const virtualEventProposition = (
      <div className="c-virtual-event-proposition">
        {isShowVEP ? (
          accessGranted ? (
            openingLinkType === 'IFRAME' ? (
              <CallToAction
                onClick={this.toggleVirtualEventIframeDisplay}
                size="medium"
                label={buttonText}
              />
            ) : (
              <CallToAction
                to={this.getBaseUrl()}
                size="medium"
                target={openingLinkType === 'NEW_TAB' ? '_blank' : '_self'}
                label={buttonText}
              />
            )
          ) : accessChecked ? (
            <div>
              <VirtualEventPropositionHeading
                isBooking={isBooking}
                title={
                  isBooking
                    ? heading ||
                      t('component.virtual-proposition.purchase-required')
                    : t('component.virtual-proposition.booking-closed')
                }
                subTitle={isBooking ? description : ''}
              />
              <CallToAction
                type="button"
                ctaConfig={{ type: 'BUY_LINK' }}
                sitePath
                className="c-virtual-event-proposition__btn-book-now"
                size="medium"
              />
            </div>
          ) : (
            <div className="c-virtual-event-proposition__loader">
              <div className="cycle-loader">Checking...</div>
            </div>
          )
        ) : (
          <div>
            <div className="c-virtual-event-proposition__access-title">
              {t('component.virtual-proposition.access-session')}
            </div>
            <CallToAction
              type="button"
              portfolioId={null}
              size="medium"
              onClick={this.handleLogInFormVisibility()}
              className={btnLoginClass}
            >
              {t('component.virtual-proposition.btn.login')}
            </CallToAction>
            <CallToAction
              type="button"
              ctaConfig={{ type: 'BUY_LINK' }}
              sitePath
              size="medium"
              className={btnBookNowClass}
            />
          </div>
        )}
        {isShowLoginForm && (
          <div className="c-virtual-event-proposition__login-form">
            <LoginForm
              formConfig={this.getFormConfig()}
              formViewType="horizontal"
              onSuccess={() => window.location.reload(false)}
              isClearSubmitCtaMargin
            />
          </div>
        )}
      </div>
    );

    const virtualEventIframe = (
      <MaximiseScreenWrapper
        enabled
        popupLayout
        onCloseHandler={this.toggleVirtualEventIframeDisplay}
      >
        <iframe
          src={this.getBaseUrl()}
          title={title}
          width="100%"
          height={iframeHeight}
        />
      </MaximiseScreenWrapper>
    );

    return iframeDisplayed
      ? virtualEventIframe
      : this.props.children({
          virtualEventIframe,
          virtualEventProposition: virtualEventEnabled
            ? virtualEventProposition
            : null,
          virtualEventEnabled,
          iframeDisplayed,
          currentSlide: sessionIndex,
        });
  }
}

VirtualEventProposition.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    virtualConfig: PropTypes.shape({
      enabled: PropTypes.bool,
      appendSessionId: PropTypes.bool,
      appendUserEmail: PropTypes.bool,
      buttonText: PropTypes.string,
      iframeHeight: PropTypes.number,
      openingLinkType: PropTypes.oneOf(['IFRAME', 'SAME_TAB', 'NEW_TAB']),
    }),
  }).isRequired,
  siteHeader: PropTypes.shape({
    data: PropTypes.shape({
      booking: PropTypes.bool,
    }),
  }),
  children: PropTypes.func.isRequired,
  handleSessionIframeOpening: PropTypes.func,
  t: PropTypes.func,
  pageConfig: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(VirtualEventProposition),
);
