import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import qs from 'qs';
import { isFunction, addQueryParamsToUrl } from '../../../helpers';
import Auth from '../../../services/AuthService';
import ReduxFormWrapper from '../../shared/ReduxFormWrapper';
import LoginFormVertical from './LoginFormVertical/LoginFormVertical';
import LoginFormHorizontal from './LoginFormHorizontal/LoginFormHorizontal';

const formViewTypes = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export class LoginForm extends Component {
  submitAction = (values) => {
    const { username, password } = values;

    return Auth.login({
      username,
      password,
    });
  };

  onSubmit = (response) => {
    const { t, onSuccess } = this.props;
    const { token, error, errorCode } = response;

    if (token) {
      Auth.setToken(response);

      if (isFunction(onSuccess)) {
        onSuccess(response);
      } else {
        this.redirectToProvidedPage();
      }

      return;
    }

    if (error) {
      let errorMessage = error;

      switch (errorCode) {
        case 'USER_NOT_FOUND':
        case 'CREDENTIALS_DOESNT_MATCH':
          errorMessage = t('component.login.errors.account-not-found');
          break;

        case 'PASSWORD_RESET_REQUIRED':
          errorMessage = t('component.login.errors.password-expired');
          break;

        default:
          break;
      }

      return {
        _error: errorMessage,
      };
    }
  };

  redirectToProvidedPage() {
    const { redirectToOnSuccess, pageConfig: { sitePath } = {} } = this.props;

    if (redirectToOnSuccess) {
      document.location.replace(decodeURIComponent(redirectToOnSuccess));
    } else {
      const urlParams = qs.parse(document.location.search.slice(1));
      const redirectUrl = (urlParams.redirectUrl || '').toString();

      if (redirectUrl) {
        document.location.replace(
          decodeURIComponent(
            addQueryParamsToUrl(redirectUrl, {
              noCache: true,
            }),
          ),
        );
      } else {
        document.location.replace('/' + sitePath + '/');
      }
    }
  }

  render() {
    const {
      formConfig: {
        textContent: {
          title,
          customTitle,
          titleSentenceCase,
          callToActionLabel,
          alternativeProposition,
          anchors,
          fields = {},
        } = {},
      } = {},
      isClearSubmitCtaMargin = false,
      formViewType = formViewTypes.VERTICAL,
    } = this.props;

    return (
      <ReduxFormWrapper
        form="loginForm"
        heading={title}
        headingSentenceCase={titleSentenceCase}
        callToAction={callToActionLabel}
        alternativeProposition={alternativeProposition}
        onSubmit={this.onSubmit}
        submitAction={this.submitAction}
        isClearSubmitCtaMargin={isClearSubmitCtaMargin}
      >
        {formViewTypes.HORIZONTAL === formViewType ? (
          <LoginFormHorizontal
            heading={customTitle}
            anchors={anchors}
            fields={fields}
          />
        ) : formViewTypes.VERTICAL === formViewType ? (
          <LoginFormVertical anchors={anchors} fields={fields} />
        ) : null}
      </ReduxFormWrapper>
    );
  }
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
  formViewType: PropTypes.oneOf(Object.values(formViewTypes)),
  isClearSubmitCtaMargin: PropTypes.bool,
  redirectToOnSuccess: PropTypes.string,
  formConfig: PropTypes.shape({
    textContent: PropTypes.shape({
      title: PropTypes.string,
      titleSentenceCase: PropTypes.bool,
      callToActionLabel: PropTypes.string,
      fields: PropTypes.shape({
        username: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
      }).isRequired,
      anchors: PropTypes.node,
    }).isRequired,
  }).isRequired,
  pageConfig: PropTypes.shape({
    sitePath: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withRouter(LoginForm)),
);
