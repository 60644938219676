import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './VirtualEventPropositionHeading.scss';

export default function VirtualEventPropositionHeading(props) {
  const { title, subTitle, isBooking } = props;
  const titleClass = classnames('c-virtual-event-proposition-heading__title', {
    'c-virtual-event-proposition-heading__title--no-margin': !isBooking,
  });

  return (
    <div className="c-virtual-event-proposition-heading">
      {title && <h3 className={titleClass}>{title}</h3>}
      {subTitle && (
        <p className="c-virtual-event-proposition-heading__subtitle">
          {subTitle}
        </p>
      )}
    </div>
  );
}

VirtualEventPropositionHeading.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isBooking: PropTypes.bool,
};
