import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '../../../shared/ReduxFormWrapper/formElements';
import { email, required } from '../../../shared/ReduxFormWrapper';

import './LoginFormHorizontal.scss';

export default function LoginFormHorizontal(props) {
  const { heading, anchors, fields: { username, password } = {} } = props;

  return (
    <div className="c-login-form-horizontal">
      {heading && (
        <h1 className="c-login-form-horizontal__heading">{heading}</h1>
      )}
      <div className="c-login-form-horizontal__wrapper">
        <div className="c-login-form-horizontal__field">
          <TextField
            type="email"
            name="username"
            label={username + ' *'}
            validate={[required, email]}
          />
        </div>
        <div className="c-login-form-horizontal__field">
          <TextField
            type="password"
            name="password"
            label={password + ' *'}
            validate={[required]}
          />
          {anchors && <div className="form-anchor">{anchors}</div>}
        </div>
      </div>
    </div>
  );
}

LoginFormHorizontal.propTypes = {
  heading: PropTypes.string,
  anchors: PropTypes.node,
  fields: PropTypes.object,
};
