import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '../../../shared/ReduxFormWrapper/formElements';
import { email, required } from '../../../shared/ReduxFormWrapper';

export default function LoginFormVertical(props) {
  const { anchors, fields: { password, username } = {} } = props;

  return (
    <div className="c-login-form-vertical">
      <div className="form-control">
        <TextField
          type="email"
          name="username"
          label={username + ' *'}
          validate={[required, email]}
        />
      </div>
      <div className="form-control">
        <TextField
          type="password"
          name="password"
          label={password + ' *'}
          validate={[required]}
          showPasswordBtn
        />
      </div>
      {anchors && <div className="form-anchor">{anchors}</div>}
    </div>
  );
}

LoginFormVertical.propTypes = {
  anchors: PropTypes.node,
  fields: PropTypes.object,
};
