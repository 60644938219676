import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { PasswordStrengthBar } from '../index';
import './TextField.scss';

const RenderTextField = (props) => {
  const {
    input,
    type,
    label,
    placeholder,
    showStrengthBar,
    showPasswordBtn,
    meta: { touched, error },
  } = props;

  const [passwordShown, showPassword] = useState(false);

  const componentClassNames = classNames('c-redux-form-input', {
    [`c-redux-form-input--type-${type}`]: type,
    'c-redux-form-input--error': touched && error,
  });

  const inputClassNames = classNames('c-redux-form-input__input', {
    'c-redux-form-input__input--with-btn': showPasswordBtn,
  });

  return (
    <div className={componentClassNames}>
      <label htmlFor={input.name} className="c-redux-form-input__label">
        <span>{label}</span>
        {touched && error && (
          <span className="c-redux-form-input__error">{error}</span>
        )}
      </label>
      <div className={inputClassNames}>
        <input
          {...input}
          type={passwordShown ? 'text' : type}
          placeholder={placeholder}
        />
        {showPasswordBtn && (
          <div
            className="c-redux-form-input__show-password-btn"
            onClick={() => {
              showPassword(!passwordShown);
            }}
          >
            <span>{passwordShown ? 'Hide' : 'Show'}</span>
          </div>
        )}
      </div>
      {showStrengthBar && <PasswordStrengthBar password={input.value} />}
    </div>
  );
};

export function TextField(props) {
  const {
    type,
    name,
    label,
    placeholder,
    className,
    validate,
    showStrengthBar,
    showPasswordBtn,
  } = props;

  return (
    <Field
      type={type}
      name={name}
      validate={validate}
      component={RenderTextField}
      label={label}
      placeholder={placeholder}
      className={className}
      showStrengthBar={showStrengthBar && type === 'password'}
      showPasswordBtn={showPasswordBtn && type === 'password'}
    />
  );
}

TextField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'tel',
    'password',
    'hidden',
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
  className: PropTypes.string,
  showStrengthBar: PropTypes.bool,
  showPasswordBtn: PropTypes.bool,
};
