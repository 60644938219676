import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { siteTypesEnum } from '../../../helpers';

import './AgendaTimeZone.scss';

export function AgendaTimeZone(props) {
  const {
    pageConfig: { siteType } = {},
    siteAgenda: { data: { timeZoneName, type } = {} } = {},
    isHyphenBefore = true,
    isModalLocation = false,
  } = props;
  const { COURSE } = siteTypesEnum();
  const isShowTimeZone = siteType === COURSE ? type === 'Scheduled' : true;
  const componentClass = classnames('c-agenda-time-zone', {
    'c-agenda-time-zone--modal': isModalLocation,
  });

  return timeZoneName && isShowTimeZone ? (
    <span className={componentClass}>
      {isHyphenBefore && (
        <span className="c-agenda-time-zone__hyphen"> - </span>
      )}
      {timeZoneName}
    </span>
  ) : null;
}

AgendaTimeZone.propTypes = {
  pageConfig: PropTypes.object,
  siteAgenda: PropTypes.object,
  isHyphenBefore: PropTypes.bool,
  isModalLocation: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteAgenda: state.siteAgenda,
  };
}

export default connect(mapStateToProps)(AgendaTimeZone);
