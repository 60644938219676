import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  getImgixUrl,
  renderHTMLString,
  splitListIntoChunks,
} from '../../../../helpers';
import { withBrand } from '../../../../providers/BrandProvider';
import Link from '../../Link/Link';
import './SessionSponsors.scss';

export function SessionSponsors(props) {
  const { sponsors = [], brand = {}, pageConfig: { tenantId } = {} } = props;

  return (
    <div className="session-sponsors">
      {splitListIntoChunks(sponsors, 3).map((sponsorRow, i) => {
        const hasTypeInRow = sponsorRow.some((item) => !!item.type);
        const rowWithType = !isMobile && hasTypeInRow;

        return (
          <div className="row" key={i}>
            {sponsorRow.map((sponsor, j) => {
              const {
                title,
                type,
                logo = {},
                path = '',
                fullLogoPath,
              } = sponsor;

              const logoPath = getImgixUrl(
                tenantId,
                fullLogoPath || logo.path,
                'w=200',
              );

              const itemWithType = isMobile && type;

              return (
                <div
                  className="col-xs-12 col-sm-4 session-sponsors__item"
                  key={`${i}-${j}`}
                >
                  {(rowWithType || itemWithType) && (
                    <span
                      className="session-sponsors__item-type"
                      style={{ color: brand.primaryColor }}
                    >
                      {type}
                    </span>
                  )}
                  <Link to={`/sponsors/${path}`} event>
                    <div>
                      <div className="session-sponsors__title">
                        {renderHTMLString(title)}
                      </div>
                      <div className="session-sponsors__image-wrapper">
                        <img
                          src={logoPath}
                          className="session-sponsors__image"
                          alt=""
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

SessionSponsors.propTypes = {
  brand: PropTypes.object,
  sponsors: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(SessionSponsors));
