import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withBrand } from '../../../../providers/BrandProvider';

import CallToAction from '../../CallToAction/CallToAction';
import Carousel from '../../Carousel/Carousel';
import { renderHTMLString } from '../../../../helpers';
import AgendaTimeStatus from '../../AgendaTimeStatus/AgendaTimeStatus';
import AgendaTimeZone from '../../AgendaTimeZone/AgendaTimeZone';
import SessionSpeakers from '../SessionSpeakers/SessionSpeakers';
import SessionSponsors from '../SessionSponsors/SessionSponsors';

import './SessionInfo.scss';
import AgendaCustomLabel from '../../../sections/AgendaTable/components/AgendaCustomLabel';

export const getBookingCTALabel = (params, t) => {
  const { props, translationKey, bookingMobileCustomText } = params;

  if (props?.siteHeader?.bookingForm?.bookingFormType === 'CUSTOM') {
    return bookingMobileCustomText;
  }

  return t(`${translationKey}.mobile`);
};

export function SessionInfo(props) {
  const {
    sessionIndex,
    closeModal = () => {},
    sessions = [],
    carouselSettings = {},
    currentSession = {},
    isLearning,
    virtualEventEnabled,
    virtualEventProposition = '',
    isDateAndTimeDisplayed,
    currentDay = {},
    t,
    brand = {},
    isBooking,
  } = props;

  const showDescription =
    !(isLearning && !currentSession.showInInfo) && currentSession.description;
  const isFreeItem = currentSession.labels?.indexOf('Free') >= 0;
  const currentFormat = currentSession.format?.toUpperCase();
  const showRegisterButton =
    currentSession.showRegisterButton && (virtualEventEnabled || isBooking);
  const showMoreInfoButton =
    currentSession.showMoreInfoButton && currentSession.moreInfoButton;

  return (
    <div className="c-session-info">
      <div
        className="c-session-info__header"
        style={{ backgroundColor: brand.secondaryColor }}
      >
        <span className="c-session-info__header-close" onClick={closeModal}>
          ×
        </span>
      </div>
      {(sessionIndex || sessionIndex === 0) && sessions.length && (
        <Carousel embedSettings={carouselSettings}>
          {sessions.map((slide, i) => {
            return <div key={i} className="sessions-carousel" />;
          })}
        </Carousel>
      )}

      <div className="c-session-info__body carousel_wrapper">
        <div
          data-stream-color-index={
            currentSession.stream &&
            typeof currentSession.stream.color === 'number'
              ? currentSession.stream.color
              : 'null'
          }
        >
          <div className="c-session-info__body-text">
            {currentFormat && currentFormat !== 'NONE' && (
              <h5 className="c-session-info__body-format">
                {t(`AgendaSessionFormat.${currentFormat}`)}
              </h5>
            )}
            {currentSession.title && (
              <h3 className="c-session-info__body-title">
                {renderHTMLString(currentSession.title)}
                {isFreeItem && (
                  <span className="agenda-item-label agenda-item-label__free">
                    {t('event.agenda.filter.label.free')}
                  </span>
                )}
                {currentSession.customLabel?.checked && (
                  <AgendaCustomLabel
                    labelConfig={currentSession.customLabel}
                    className="agenda-item-label"
                  />
                )}
              </h3>
            )}
            {showDescription && (
              <div className="c-session-info__body-description">
                {renderHTMLString(currentSession.description)}
              </div>
            )}

            {currentSession.stream && currentSession.stream.name && (
              <div className="c-session-info__body-stream stream-color">
                {currentSession.stream.name}
              </div>
            )}
            {isDateAndTimeDisplayed && (
              <div className="c-session-info__body-time">
                <AgendaTimeStatus
                  session={currentSession}
                  textSize="medium"
                  currentDay={currentDay}
                  isBoldStatus
                  isDataDisplayed
                />
              </div>
            )}
            <AgendaTimeZone isHyphenBefore={false} isModalLocation />
          </div>

          {(showRegisterButton || showMoreInfoButton) && (
            <div className="c-session-info__body-virtual-event">
              {showRegisterButton && (
                <div>
                  {virtualEventEnabled ? (
                    virtualEventProposition
                  ) : (
                    <>
                      <div className="visible-xs">
                        <CallToAction
                          size="medium"
                          ctaConfig={{ type: 'BUY_LINK' }}
                          className="c-session-info__body-booking-btn"
                          noWrap
                          isAutoWidthOnMobile
                        >
                          {(params) => getBookingCTALabel(params, t)}
                        </CallToAction>
                      </div>
                      <div className="hidden-xs">
                        <CallToAction
                          size="medium"
                          ctaConfig={{ type: 'BUY_LINK' }}
                          className="c-session-info__body-booking-btn"
                          noWrap
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {showMoreInfoButton && (
                <div>
                  <CallToAction
                    size="medium"
                    ctaConfig={currentSession.moreInfoButton}
                    className="c-session-info__body-booking-btn"
                    noWrap
                  />
                </div>
              )}
            </div>
          )}

          {currentSession.speakers?.length > 0 && (
            <SessionSpeakers speakers={currentSession.speakers} />
          )}
          {currentSession.sponsors?.length > 0 && (
            <SessionSponsors sponsors={currentSession.sponsors} />
          )}
        </div>
      </div>
    </div>
  );
}

SessionInfo.propTypes = {
  sessionIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeModal: PropTypes.func,
  sessions: PropTypes.array,
  carouselSettings: PropTypes.object,
  currentSession: PropTypes.object,
  isLearning: PropTypes.bool,
  virtualEventEnabled: PropTypes.bool,
  virtualEventProposition: PropTypes.object,
  isDateAndTimeDisplayed: PropTypes.bool,
  currentDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  t: PropTypes.func,
  isBooking: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(SessionInfo));
