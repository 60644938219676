import Patterns from '../../../utils/pattern/patterns';
import ReduxFormWrapper from './ReduxFormWrapper';

export default ReduxFormWrapper;

export function required(value) {
  return value || typeof value === 'number' ? undefined : 'Required';
}

export function password(value) {
  return Patterns.isPassword(value)
    ? undefined
    : 'Password is not strong enough';
}

export function confirmPassword(value, values) {
  return value === values.password
    ? undefined
    : "Confirm password doesn't match password";
}

export function email(value) {
  return Patterns.isEmail(value)
    ? undefined
    : 'Please enter a valid email address';
}
