import React from 'react';
import PropTypes from 'prop-types';
import patterns from '../../../../../utils/pattern/patterns';
import './PasswordStrengthBar.scss';

export function PasswordStrengthBar({ password = '' }) {
  const minLengthValid = password.length >= patterns.passwordLength;
  const hasUpperCaseLetter = /[A-Z]/.test(password);
  const hasLowerCaseLetter = /[a-z]/.test(password);
  const hasNumber = /[\d]/.test(password);

  return (
    <div className="c-password-strength-bar">
      <span className="c-password-strength-bar__text">
        Minimum requirements for a <strong>strong</strong> password:
      </span>
      <ul>
        <li className={minLengthValid ? 'valid' : 'invalid'}>8 characters</li>
        <li className={hasUpperCaseLetter ? 'valid' : 'invalid'}>
          1 uppercase
        </li>
        <li className={hasLowerCaseLetter ? 'valid' : 'invalid'}>
          1 lowercase
        </li>
        <li className={hasNumber ? 'valid' : 'invalid'}>1 number</li>
      </ul>
    </div>
  );
}

PasswordStrengthBar.propTypes = {
  password: PropTypes.string,
};
